import Vue from 'vue'
import App from './App.vue'
import router from './router'
import mixin from './mixin/mixin'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

import 'wowjs/css/libs/animate.css';
//这里需要将样式用起来，
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(mixin)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
